import { makeStyles } from "@material-ui/core/styles";
import * as yup from 'yup';
import * as React from 'react';
import { useState } from 'react';
import { Helmet } from "react-helmet";
import { Button, Divider, Grid, IconButton, Snackbar, TextField, Typography } from "@material-ui/core";
import { useFormik } from "formik";
import MuiAlert from '@material-ui/lab/Alert';
import { Close } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '85px',
    padding: '15px',
    '& .MuiTypography-root': {
      margin: theme.spacing(1)
    }
  },
  form: {
    '& .MuiTextField-root, & .MuiButton-root': {
      margin: theme.spacing(1)
    }
  }
}));

const validationSchema = yup.object({
  name: yup.string('Your Name')
    .min(3, 'Your name probably has more than 3 letters')
    .max(100, 'Your name shouldn\'t contain more than 100 letters (if it does, poor you)')
    .required('Your name is required'),
  emailAddress: yup.string('Your email')
    .email('Enter a valid email address so I can get back to you')
    .required('Your email address is required'),
  message: yup.string('Your Message')
    .required('You probably want to say something, right?')
});

const Contact = () => {
  const classes = useStyles();
  const [success, setSuccess] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const formik = useFormik({
    initialValues: {
      emailAddress: '',
      name: '',
      message: ''
    },
    validationSchema,
    onSubmit: (values) => {
      console.log(values);

      return fetch('https://bvhs4rh286.execute-api.eu-west-1.amazonaws.com/prod/contact-me',
        {
          method: 'POST',
          body: JSON.stringify(values)
        })
        .then(response => response.json())
        .then(data => {
          console.log('Success, ', data);

          setSuccess(true);
          formik.resetForm();
        })
        .catch(error => {
          setSuccess(false);
          console.error(error);
        })
        .finally(() => {
          setSnackbarOpen(true);
        });
    },
  });

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
  }

  return (
    <div className={classes.root}>
      <Helmet>
        <title>Contact Me | Thomas Bezuidenhout</title>
      </Helmet>
      <Grid container spacing={2} justify="center" alignItems="center">
        <Grid item xs={10}>
          <Typography variant="h1">Send Me a Message</Typography>
          <Typography variant="body1" ml={2}>
            I'd be happy to hear from you!
          </Typography>
          <Divider variant="middle" />
        </Grid>
        <Grid item xs={10}>
          <form className={classes.form} noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
            <TextField
              fullWidth
              id="name"
              name="name"
              variant="outlined"
              label="Your Name"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
            <TextField
              fullWidth
              id="emailAddress"
              name="emailAddress"
              variant="outlined"
              label="Your Email Address"
              type="email"
              value={formik.values.emailAddress}
              onChange={formik.handleChange}
              error={formik.touched.emailAddress && Boolean(formik.errors.emailAddress)}
              helperText={formik.touched.emailAddress && formik.errors.emailAddress}
            />
            <TextField
              fullWidth
              id="message"
              name="message"
              variant="outlined"
              label="Your Message"
              multiline
              rows={4}
              rowsMax={6}
              value={formik.values.message}
              onChange={formik.handleChange}
              error={formik.touched.message && Boolean(formik.errors.message)}
              helperText={formik.touched.message && formik.errors.message}
            />
            <Button fullWidth type="submit" variant="outlined">Submit</Button>
          </form>
        </Grid>
      </Grid>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
        message={success ? 'Thanks for your message!' : 'Something went wrong, try again later?'}
        action={
          <IconButton size="small" aria-label="close" color="inherit" onClick={handleSnackbarClose}>
            <Close fontSize="small" />
          </IconButton>
        }
      />
    </div>
  )
};

export default Contact;
