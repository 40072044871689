import * as React from 'react';
import { useState } from "react";
import {IconButton, List, ListItem, ListItemText, Drawer, Divider} from "@material-ui/core";
import { Menu } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core";
import {Link} from "react-router-dom";

const useStyles = makeStyles({
  list: {
    width: 250
  },
  linkText: {
    textDecoration: 'none',
    textTransform: 'uppercase',
    color: 'black'
  }
});

const SideDrawer = ({ navLinks }) => {
  const [state, setState] = useState({ right: false });
  const classes = useStyles();

  const renderItemOrDivider = ({ title, path }) => {
    if (title === null) {
      return (
        <Divider key={path} />
      )
    } else {
      return (
        <Link to={path} key={title} className={classes.linkText}>
          <ListItem button>
            <ListItemText primary={title}/>
          </ListItem>
        </Link>
      )
    }
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ [anchor]: open });
  }

  const sideDrawerList = anchor => (
    <div className={classes.list} role="presentation" onClick={toggleDrawer(anchor, false)} onKeyDown={toggleDrawer(anchor, false)}>
      <List component="nav">
        {navLinks.map(item => renderItemOrDivider(item) )}
      </List>
    </div>
  )

  return (
    <React.Fragment>
      <IconButton edge="start" aria-label="menu" onClick={toggleDrawer('right', true)}>
        <Menu fontSize="large" style={{color: 'white'}}/>
      </IconButton>
      <Drawer anchor="right" open={state.right} onClose={toggleDrawer('right', false)}>
        {sideDrawerList('right')}
      </Drawer>
    </React.Fragment>
  );
};

export default SideDrawer;
