import './Home.css';
import {Grid, makeStyles, Typography} from "@material-ui/core";
import header from './header.jpg';
import {Helmet} from "react-helmet";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    minHeight: '100vh',
  },
  headerGrid: {
    backgroundImage: `url(${header})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    minHeight: '100vh',
  }
}));

function Home() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Helmet>
        <title>Home | Thomas Bezuidenhout</title>
      </Helmet>
      <Grid container spacing={0} className={classes.headerGrid} justify="center" alignItems="flex-end" direction="row">
        <Typography variant="body1" align="center" gutterBottom={true} style={{ color: 'white' }} >
          &copy; Thomas Bezuidenhout {new Date().getUTCFullYear()}
        </Typography>
      </Grid>
    </div>
  );
}

export default Home;
