import './Gallery.css';
import { makeStyles } from '@material-ui/core/styles';
import Masonry from 'react-masonry-css';
import { Card, CardActionArea, CardMedia, Grid } from '@material-ui/core';
import { SRLWrapper } from 'simple-react-lightbox';
import ReactGA from 'react-ga';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gallery: {
    maxWidth: '100%',
    // maxHeight: '650px',
  },
  cardImage: {
    maxHeight: '80vh',
    objectFit: 'contain'
  }
}));

const breakpointColumnsObj = {
  default: 4,
  1100: 3,
  700: 2,
  500: 1
}

const lightboxOptions = {
  buttons: {
    showDownloadButton: false
  }
}

const triggerGAEvent = (img, action) => {
  try {
    ReactGA.event({
      'category': 'Image',
      'action': action,
      'label': img.title,
    });
  } catch (err) {
    console.warn(err)
  }
};

const Gallery = ({ images }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container justify="center">
        <Grid item xs={12}>
          <SRLWrapper options={lightboxOptions}>
            <Masonry breakpointCols={breakpointColumnsObj} className="my-masonry-grid" columnClassName="my-masonry-grid_column">
              {images.sort( () => .5 - Math.random() ).map((item, i) => (
                <Card key={i} style={{ boxShadow: 'none' }}>
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      alt={item.title}
                      className={classes.cardImage}
                      image={item.img}
                      title={item.title} 
                      onMouseEnter={() => triggerGAEvent(item, 'hover')}
                      onClick={() => triggerGAEvent(item, 'click')}
                      />
                  </CardActionArea>
                </Card>
              ))}
            </Masonry>
          </SRLWrapper>
        </Grid>
      </Grid>
    </div>
  );
};

export default Gallery;
