import Gallery from "../../components/Gallery/Gallery";
import {makeStyles} from "@material-ui/core/styles";
import {Grid, Typography} from "@material-ui/core";
import {Helmet} from "react-helmet";
import {useEffect, useState} from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '85px',
    padding: '15px',
  }
}));

const Favourites = () => {
  const classes = useStyles();
  const [images, setImages] = useState([]);

  useEffect(() => {
    fetch("/img/favourites/favourites.json")
      .then(response => {
        return response.json();
      })
      .then(data => {
        setImages(data);
      });
  }, [setImages]);

  return (
    <div className={classes.root}>
      <Helmet>
        <title>Favourites | Thomas Bezuidenhout</title>
      </Helmet>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h1" align="center">
            Favourites
          </Typography>
          <Typography gutterBottom={true} align="center">
            These are some of my favourite images that I've shot over the past few years of my photography hobby
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Gallery images={images} />
        </Grid>
      </Grid>
    </div>
  )
};

export default Favourites;
