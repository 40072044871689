import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from "react-router-dom";
import { unstable_createMuiStrictModeTheme as createMuiTheme, MuiThemeProvider } from "@material-ui/core";
import blueGrey from '@material-ui/core/colors/blueGrey';
import yellow from '@material-ui/core/colors/yellow';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Header from "./components/Header/Header";
import SimpleReactLightbox from 'simple-react-lightbox';

const theme = createMuiTheme({
  palette: {
    primary: blueGrey,
    secondary: yellow
  },
  typography: {
    h1: {
      fontSize: '4rem',
    },
    h2: {
      fontSize: '3rem',
    }
  }
});

ReactDOM.render(
  <React.StrictMode>
    <SimpleReactLightbox>
      <Router>
        <MuiThemeProvider theme={theme}>
          <Header />
          <App />
        </MuiThemeProvider>
      </Router>
    </SimpleReactLightbox>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
